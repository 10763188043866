<template>
	<div class="special-offers">
		<div class="special-offers__title">
			<h2 class="main-gallery__hdng">
				{{ $t('ads.specialLoanTitle') }}
			</h2>
		</div>
		<div class="special-offers__body">
			<SpecialOffersCard
				v-for="(item, index) in loanOffersList"
				:key="index"
				:content="item"
			/>
		</div>
	</div>
</template>

<script>
import SpecialOffersCard from '@/components/cards/SpecialOffers'
export default {
	name: 'SpecialOffers',
	components: {
		SpecialOffersCard
	},
	props: ['loanOffersList']
}
</script>
<style lang="scss">
.special-offers {
	&__title {
		margin-bottom: 20px;
		h2 {
			font-size: 18px;
			font-weight: 700;
		}
	}
	&__body {
		display: flex;
		flex-direction: column;
		grid-gap: 10px;
		max-width: 680px;
	}
	@media screen and (max-width: 991px) {
		&__body {
			flex-direction: column;
		}
	}
	@media screen and (max-width: 479px) {
		&__body {
			gap: 5px;
		}
	}
}
</style>
