<template>
	<svg
		width="24"
		height="23"
		viewBox="0 0 24 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.907316 21.7791C1.07697 21.3535 1.27504 20.8395 1.46381 20.3088C1.69924 19.6469 1.92497 18.9458 2.06139 18.3534C2.12931 18.0584 2.17898 17.774 2.19295 17.5266C2.20536 17.3071 2.19836 17.0088 2.05148 16.7709C1.06433 15.1718 0.500032 13.3147 0.500032 11.3333C0.500032 5.37679 5.62145 0.5 12 0.5C18.3786 0.5 23.5 5.37679 23.5 11.3333C23.5 17.2899 18.3786 22.1667 12 22.1667C9.8991 22.1667 7.93217 21.6363 6.23975 20.7117C5.99833 20.5798 5.70309 20.5559 5.44908 20.561C5.17819 20.5664 4.87075 20.6079 4.55219 20.669C3.91359 20.7917 3.17176 21.0053 2.47597 21.2309C1.90516 21.416 1.35635 21.6121 0.907316 21.7791Z"
			stroke="white"
		/>
		<path
			d="M17.4159 13.5849C17.1213 13.4432 15.6588 12.7653 15.3856 12.6742C15.1124 12.5781 14.9141 12.5325 14.7159 12.8159C14.5177 13.0992 13.9499 13.7266 13.7731 13.9188C13.6016 14.106 13.4249 14.1313 13.1302 13.9897C11.3838 13.165 10.2374 12.5173 9.08557 10.6504C8.78022 10.1545 9.39093 10.19 9.95879 9.11734C10.0552 8.93014 10.007 8.76823 9.932 8.62657C9.857 8.4849 9.26236 7.10365 9.01593 6.54204C8.77486 5.99562 8.52843 6.07151 8.34629 6.06139C8.17486 6.05127 7.97665 6.05127 7.77843 6.05127C7.58022 6.05127 7.25879 6.1221 6.98557 6.40038C6.71236 6.68371 5.94629 7.36169 5.94629 8.74294C5.94629 10.1242 7.01236 11.4599 7.157 11.6471C7.307 11.8343 9.25165 14.6676 12.2356 15.887C14.1213 16.656 14.8606 16.7218 15.8034 16.5903C16.3766 16.5093 17.5606 15.9123 17.807 15.2545C18.0534 14.5968 18.0534 14.0352 17.9784 13.9188C17.9088 13.7923 17.7106 13.7215 17.4159 13.5849Z"
			fill="white"
		/>
	</svg>
</template>
