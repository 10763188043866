<template>
	<div class="tooltip">
    <div
      class="tooltip__item"
      v-for="(value, key) in price"
      :key="key"
    >
      <span>
				{{ formatNumber(value) }}
			</span>
      <p>{{ key.toUpperCase() === 'DOLLAR' ? 'USD' : key.toUpperCase() }}</p>
    </div>
	</div>
</template>

<script>
import { formatNumber } from '@/utils/formatMoney'

export default {
	name: 'Tooltip',
	props: ['price'],
	methods: {
		formatNumber
	}
}
</script>

<style lang="scss">
.tooltip {
	position: absolute;
	top: -275px;
	right: 0;
	padding: 5px 10px;
	min-width: 100px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap: 20px;
	background: #f2f2f2;
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease-in;
	z-index: 1;

	@media (max-width: 600px) {
		min-width: 0;
		width: auto;
		left: 0;
		right: auto;
		//top: auto;
		//bottom: -30px;
	}

	@media (max-width: 400px) {
		flex-direction: column;
		align-items: flex-start;
		//bottom: -85px;
	}

	&::before {
		position: absolute;
		content: '';
		right: 10px;
		bottom: -5px;
		width: 11px;
		height: 6px;
		background-image: url('~@/assets/images/tooltip-before.svg');

		//@media (max-width: 600px) {
		//	right: auto;
		//	left: 10px;
		//	top: -5px;
		//	transform: rotate(180deg);
		//}
	}

	&__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		grid-gap: 5px;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		text-align: right;

		span {
			color: #4d4d4d;
		}

		p {
			margin: 0;
			color: #c99d56;
		}
	}
}
</style>
