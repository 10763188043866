<template>
	<div class="offers-card">
		<span class="offers-card__title">{{ content.title }}</span>
		<div class="offers-card__text" v-html="content.description"></div>
	</div>
</template>

<script>
export default {
	name: 'SpecialOffers',
	props: ['content']
}
</script>

<style lang="scss">
.offers-card {
	border: 1px solid #8080804a;
	border-radius: 5px;
	display: flex;
	align-items: center;
	gap: 20px;
	width: 100%;
	padding: 20px;
	&__title {
		color: $brown-main;
		font-size: 28px;
		font-weight: 700;
		min-width: 58px;
	}
	&__text {
		font-weight: 400;
		font-size: 14px;
	}
	@media screen and (max-width: 479px) {
		padding: 20px 14px;
		gap: 10px;
		&__title {
			font-size: 24px;
			min-width: 49px;
		}
		&__text {
			font-size: 12px;
		}
	}
}
</style>
