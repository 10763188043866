<template>
	<ul class="showcase">
		<li class="showcase__item showcase-item">
			<h3 class="showcase-item__title">{{ $t('ads.stagesTitle') }}</h3>
			<span class="showcase-item__text">
				{{ dateQuarter }}
			</span>
		</li>
		<template v-for="item in list" :key="item.title">
			<li v-if="item.isShow" class="showcase__item showcase-item">
				<router-link :to="item.link" class="showcase-item__link">
					{{ item.title }}
				</router-link>
				<span class="showcase-item__text">
					{{ item.text }}
				</span>
			</li>
		</template>
	</ul>
</template>

<script>
import { getQuarterDate } from '@/utils/dateTime.js'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		list() {
			return [
				{
					title: this.content.project?.title,
					text: this.$t('general.project'),
					isShow: this.content.project?.slug,
					link: {
						name: 'SoloProject',
						params: {
							lang: this.$store.state.uiLanguage,
							slug: this.content.project?.slug
						}
					}
				},
				{
					title: this.content.complex?.title,
					text: this.$t('general.complex'),
					isShow: this.content.complex?.slug,
					link: {
						name: 'SoloComplex',
						params: {
							lang: this.$store.state.uiLanguage,
							slug: this.content.complex?.slug
						}
					}
				},
				{
					title: this.content.developer?.title,
					text: this.$t('general.developer'),
					isShow: this.content.developer?.slug,
					link: {
						name: 'SoloDeveloper',
						params: {
							lang: this.$store.state.uiLanguage,
							slug: this.content.developer?.slug
						}
					}
				}
			]
		},
		dateQuarter() {
			return `${
				Date.now() > new Date(this.content.date_complete).getTime()
					? this.$t('general.сompleted')
					: this.$t('general.finish')
			} ${getQuarterDate(this.content.date_complete)}`
		}
	}
}
</script>

<style lang="scss">
.showcase {
	display: flex;
	flex-wrap: wrap;

	.showcase-item {
		width: 24%;

		&__title {
			margin: 0;
			margin-bottom: 3px;
		}

		&__link {
			font-size: 18px;
			font-weight: 700;
			color: #c99d56;

			&:hover {
				color: #b98024;
			}
		}

		&__text {
			display: block;
			font-size: 14px;
			line-height: 18px;
			color: #808080;
		}
	}

	@media screen and (max-width: 768px) {
		.showcase-item {
			width: 100%;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
</style>
