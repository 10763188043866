<template>
	<div class="immobilien">
		<div v-if="!isLoad" class="immobilien__container">
			<Description
				class="immobilien__description"
				:content="content.description"
			/>

			<ul class="immobilien__tags immobilien-tags">
				<li v-for="tag in tags" :key="tag">
					{{ tag }}
				</li>
			</ul>
		</div>
		<or-skeleton v-else class="immobilien__skeleton" :class="type" />

		<div
			v-if="!isLoad"
			class="immobilien__container immobilien__container--info"
		>
			<ContactCard v-if="content.realtor" :content="content.realtor" />
			<InfoCard
				:type="type"
				:slug="content.slug"
				:features="features[type]"
				:plan="plan"
				:address="address"
				:licence="licence"
			/>
		</div>
		<or-skeleton v-else class="immobilien__skeleton" :class="type" />
	</div>
</template>

<script>
import InfoCard from './components/InfoCard/index.vue'
import Description from '@/components/SoloPages/Description.vue'
import ContactCard from './components/ContactCard.vue'
import { simbol, money } from '@/enums/moneyСollections'
import { formatNumber } from '@/utils/formatMoney'
import {squareSymbols} from "@/enums/areaCollections";

export default {
	components: { Description, InfoCard, ContactCard },
	props: {
		type: {
			type: String,
			default: 'estate' // estate/project/broker. Вывод характеристик для различных типов объектов
		},
		isLoad: {
			type: Boolean,
			default: false
		},
		content: {
			type: Object,
			default: () => ({})
		},
		plan: {
			type: Object,
			default: () => ({})
		},
		address: {
			type: Object,
			default: () => ({})
		},
		licence: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		features() {
			return {
				estate: this.estateFeatures,
				broker: this.projectFeatures,
				project: this.projectFeatures,
				offer: this.estateFeatures
			}
		},
		estateFeatures() {
			return [
				{
					icon: 'floor',
					title: this.content.floor,
					text: this.$t('ads.floor')
				},
				{
					icon: 'bed',
					title: this.content.is_studio
						? this.$t('ads.studio')
						: this.content.beds_count,
					text: this.content.is_studio ? null : this.$t('ads.rooms')
				},
				{
					icon: 'bathtub',
					title: this.content.closet_count,
					text: this.$t('ads.baths')
				},
				{
					icon: 'furnishing',
					title: this.content.furnished ? 'Yes' : 'No',
					text: this.$t('ads.furnished'),
					class: this.content.furnished ? '' : 'feature-gray'
				},
				{
					icon: 'balcony',
					title: this.content.with_balcony ? 'Yes' : 'No',
					text: this.$t('ads.balcony'),
					class: this.content.with_balcony ? '' : 'feature-gray'
				}
			]
		},
		projectFeatures() {
			const {
				area_from,
				area_to,
				bedrooms_range,
				price_from,
				price_to,
				has_studios
			} = this.content
			let actualPrice = 0

			if (price_from) {
				actualPrice =
					`${simbol[this.$store.state.activeMoney]}${formatNumber(
						price_from[money[this.$store.state.activeMoney]]
					)}` ===
					`${simbol[this.$store.state.activeMoney]}${formatNumber(
						price_to[money[this.$store.state.activeMoney]]
					)}`
						? `${simbol[this.$store.state.activeMoney]}${formatNumber(
								price_from[money[this.$store.state.activeMoney]]
						  )}`
						: `${simbol[this.$store.state.activeMoney]}${formatNumber(
								price_from[money[this.$store.state.activeMoney]]
						  )}  — ${simbol[this.$store.state.activeMoney]}${formatNumber(
								price_to[money[this.$store.state.activeMoney]]
						  )}`
			}

      let formattedArea = ''

      if (area_from) {
        formattedArea = area_from[this.$store.state.areaUnit]
        if (area_from?.square_meter !== area_to?.square_meter) {
          formattedArea += ' - ' + area_to[this.$store.state.areaUnit]
        }
        formattedArea += ' ' + squareSymbols[this.$store.state.areaUnit]
      }

      let rooms = ''
      if (has_studios) rooms += `<span>Studio</span> `
      if (bedrooms_range?.from) {
        rooms += `<span>${bedrooms_range?.from}</span>`
        if (bedrooms_range?.to !== bedrooms_range.from) {
          rooms += ` - ${bedrooms_range?.to}</span>`
        }
      }

			return [
				{
					icon: 'money',
					title: actualPrice
				},
				{
					icon: 'square',
					title: formattedArea,
					text: this.$t('general.square')
				},
				{
					icon: 'bed',
					title: rooms,
					text: bedrooms_range?.from ? this.$t('ads.rooms') : null
				}
			]
		},
		tags() {
			return this.content.tags?.filter((itm) => itm)
		}
	}
}
</script>

<style lang="scss">
.immobilien {
	display: flex;
	justify-content: space-between;

	&__description {
		max-width: 580px;
	}

	&__tags {
		margin-top: 45px;
	}

	&__container {
		max-width: 480px;
		width: 100%;
	}

	&__skeleton {
		max-width: 480px;
		width: 100%;
		height: 499px;

		&.project {
			height: 262px;
		}
	}

	.immobilien-tags {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		li {
			padding: 10px;
			font-size: 16px;
			color: #808080;
			line-height: 24px;
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, 0.1);
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;

		&__container {
			max-width: 100%;
			flex-basis: 100%;

			&:first-child {
				margin-bottom: 30px;
			}
		}
	}
}
</style>
