<template>
	<div class="tags-list">
		<h2 @click="toggle" :class="{ active: isShow }" class="tags-list__title">
			{{ title || $t('ads.tagTitle2') }}
			<or-icon type="arrow" />
		</h2>
		<transition name="slide-fade">
			<ul v-if="isShow" class="tags-list__list">
				<li v-for="(tag, index) in content" :key="index">{{ tag }}</li>
			</ul>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			isShow: false
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
		}
	}
}
</script>

<style lang="scss">
.tags-list {
	&__title {
		margin: 0;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
		display: flex;
		align-items: center;
		gap: 20px;
		cursor: pointer;
		i {
			transition: all 0.2s ease;
			margin-top: 2px;
		}
		&.active {
			i {
				transform: rotate(-180deg);
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	li {
		font-size: 16px;
		color: #808080;
		line-height: 24px;
		padding: 10px;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	// Animation
	.slide-fade-enter-active {
		transition: all 0.2s linear;
	}
	.slide-fade-leave-active {
		transition: all 0.2s linear;
	}
	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(-10px);
		opacity: 0;
	}
}
</style>
