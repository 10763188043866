<template>
	<div class="info-card">
		<div class="info-card__location info-location">
			<span class="info-location__title">{{ address.city?.title }}</span>
			<router-link
				:to="{
					name: 'SoloArea',
					params: {
						slug: address.region?.slug || 1,
						lang: $store.state.uiLanguage
					}
				}"
				class="info-location__link"
			>
				{{ address.region?.title }}
				<or-icon type="arrow" />
			</router-link>
			<button type="button" @click="showMap" class="btn info-location__map">
				{{ $t('general.map') }}
			</button>
		</div>
		<div class="info-card__features">
			<component :is="featuresComponent[type]" :content="features" />
		</div>
		<div v-if="isShowDocsBlock" class="info-card__docs info-docs">
			<or-button
				v-if="plan?.url"
				@click="downloadFloorPlan"
				kind="solid-border"
				class="info-docs__btn"
			>
				Floor plan .PDF
			</or-button>
			<or-button
				v-if="licence?.url"
				@click="downloadLicense"
				kind="solid-border"
				class="info-docs__btn"
			>
				License .PDF
			</or-button>
		</div>
		<div class="info-card__btns">
			<or-button
				v-if="!isOffer && !isBroker"
				@click="showBuyModal"
				class="w-full"
			>
				{{ $t('general.buyApartment') }}
			</or-button>
			<or-button
				v-if="isBroker"
				class="w-full"
				:height="48"
        :disabled="disableUnitsButton"
        @click="$router.push({
					name: 'Units',
					query: { ...this.$route.query },
					params: { lang: $store.state.uiLanguage, pr_slug: slug }
				})"
			>
        {{ $t('web.show_units') }} {{disableUnitsButton ? '(' +  $t('web.subscription_required') + ')' : ''}}
			</or-button>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import {createRequestBroker} from "@/api/requestBuilder";
import requestBrokerConfigs from "@/api/requestBrokerConfigs";

export default {
	components: {
		EstateFeatures: defineAsyncComponent(() =>
			import('./components/EstateFeatures.vue')
		),
		ProjectFeatures: defineAsyncComponent(() =>
			import('./components/ProjectFeatures.vue')
		)
	},
	props: {
		type: {
			type: String,
			default: 'estate' // estate/project/broker. Вывод характеристик для различных типов объектов
		},
		slug: {
			type: String,
			default: ''
		},
		features: {
			type: Array,
			default: () => []
		},
		address: {
			type: Object,
			default: () => ({})
		},
		licence: {
			type: Object,
			default: () => ({})
		},
		plan: {
			type: Object,
			default: () => ({})
		},
	},
  data() {
    return {
      disableUnitsButton: true
    }
  },
  beforeMount() {
    if (this.isBroker) {
      createRequestBroker(requestBrokerConfigs.GETCheckActiveSubscription).then((response) => {
        this.disableUnitsButton = !response.response.is_active_subscription
      })
    }
  },
  computed: {
		isShowDocsBlock() {
			return this.plan?.url || this.licence?.url
		},
		isOffer() {
			return this.type === 'offer'
		},
		isBroker() {
			return this.type === 'broker'
		},
		featuresComponent() {
			return {
				estate: 'EstateFeatures',
				project: 'ProjectFeatures',
				broker: 'ProjectFeatures',
				offer: 'ProjectFeatures'
			}
		}
	},
	methods: {
		showBuyModal() {
			this.$store.commit('showModal', {
				name: 'CreateRequestBuy'
			})
		},
		downloadFloorPlan() {
			if (this.plan?.url) {
				window.open(this.plan?.url)
			}
		},
		downloadLicense() {
			if (this.licence?.url) {
				window.open(this.licence?.url)
			}
		},
		showMap() {
			this.$store.commit('showModal', {
				name: 'MapModal',
				props: {
					slug: this.slug,
					lng: this.address.lng,
					lat: this.address.lat
				}
			})
		}
	}
}
</script>

<style lang="scss">
.info-card {
	padding: 20px 30px;

	&__location {
		margin-bottom: 35px;
	}

	.info-location {
		position: relative;

		&__title {
			display: block;
			margin-bottom: 3px;
			font-size: 16px;
			font-weight: 700;
		}

		&__link {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 14px;
			color: #c99d56;

			.icon:before {
				transform: rotate(270deg);
			}

			&:hover {
				color: #b98024;
			}
		}

		&__map {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70px;
			height: 70px;
			margin: auto 0;
			border-radius: 50%;
			font-weight: 700;
			font-size: 14px;
			line-height: 19px;
			color: #ffffff;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
				url('~@/assets/images/bg-map.png');
			border: 1px solid #f7f7f7;
			cursor: pointer;
		}
	}

	&__docs {
		margin-bottom: 17px;
	}

	.info-docs {
		display: flex;
		gap: 10px;
		justify-content: space-between;

		&__btn {
			width: 100%;
		}
	}
}
</style>
