<template>
	<div class="title-line">
		<div class="title-line__container title-line__container--left">
			<div class="title-line__content__tags">
				<ul v-if="!isLoad" class="title-line__tags title-tags">
					<li v-for="flag in content.flags" :key="flag">{{ flag }}</li>
				</ul>
				<or-skeleton v-else class="title-line__tags--skeleton" />
			</div>
			<div class="title-line__content title-line__content--title">
				<h2 v-if="!isLoad" class="title-line__title">
					{{ title }}
				</h2>
				<or-skeleton v-else class="title-line__title--skeleton" />

				<span v-if="!isLoad" class="title-line__build-status">
					{{ content.build_status?.title }}
				</span>
				<or-skeleton v-else class="title-line__build-status--skeleton" />

				<p v-if="!isLoad" class="title-line__build-date">
					<span>{{ $t('mobile.realEstate_finished') }}</span>
					~&nbsp;{{ buildTime }}
				</p>
				<or-skeleton v-else class="title-line__build-date--skeleton" />
			</div>
			<div class="title-line__content title-line__content--sub">
				<template v-if="!isUnit">
					<span v-if="!isLoad" class="title-line__project">
						“{{ content.type?.title || content.type }}” {{ $t('ads.of') }}
						<router-link
							:to="{
								name: subLink,
								params: {
									slug: project.slug || 1,
									lang: $store.state.uiLanguage
								}
							}"
							class="header__link--active"
						>
							“{{ project.title }}”
						</router-link>
					</span>
					<or-skeleton v-else class="title-line__project--skeleton" />
				</template>
				<span v-if="!isLoad" class="title-line__id">
					ID:{{ content.system_number || content.system_id }}
				</span>
				<or-skeleton v-else class="title-line__id--skeleton" />
				<template v-if="!isLoad">
					<span v-if="content.created_at" class="title-line__time">
						{{ createTime }}
					</span>
				</template>
				<or-skeleton v-else class="title-line__time--skeleton" />
			</div>
		</div>
		<div
			v-if="!broker && !isProject"
			class="title-line__container title-line__container--right"
		>
			<div class="title-line__content title-line__content--price">
				<span v-if="!isLoad" class="title-line__price">{{ price }}</span>
				<or-skeleton v-else class="title-line__price--skeleton" />
			</div>
			<Tooltip :price="content.price" />
			<div class="title-line__content title-line__content--square">
				<span v-if="!isLoad" class="title-line__square">
					{{ square || 0 }} <sup>{{ squareSymbol }}</sup>
				</span>
				<or-skeleton v-else class="title-line__square--skeleton" />
			</div>
		</div>
	</div>
</template>

<script>
import { getDateInFormat, getQuarterDate } from '@/utils/dateTime.js'
import { simbol, money } from '@/enums/moneyСollections'
import { squareSymbols } from '@/enums/areaCollections'
import { formatNumber } from '@/utils/formatMoney'

import Tooltip from '@/components/Tooltip.vue'

export default {
	components: {
		Tooltip
	},
	props: {
		isLoad: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: '' // Для estate выводим цену и площидь
		},
		content: {
			type: Object,
			default: () => ({})
		},
		broker: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isEstate() {
			return this.type === 'estate'
		},
		isProject() {
			return this.type === 'project'
		},
		isUnit() {
			return this.type === 'unit'
		},
		subLink() {
			return this.isProject ? 'SoloArea' : 'SoloProject'
		},
		title() {
			return this.content.full_name || this.content.title
		},
		typeTitle() {
			return this.isUnit
				? this.title
				: this.content.type?.title || this.content.type
		},
		price() {
			return `${simbol[this.$store.state.activeMoney]}${
				this.content.price
					? formatNumber(
							this.content.price[money[this.$store.state.activeMoney]]
					  )
					: 0
			}`
		},
		square() {
			return this.content.area
				? this.content.area[this.$store.state.areaUnit]
				: ''
		},
		squareSymbol() {
			return squareSymbols[this.$store.state.areaUnit]
		},
		createTime() {
			return getDateInFormat(
				this.content.created_at,
				this.$store.state.uiLanguage
			)
		},
		buildTime() {
			return this.isProject || this.isUnit
				? getQuarterDate(this.content?.date_complete)
				: getDateInFormat(
						this.content?.date_complete,
						this.$store.state.uiLanguage
				  )
		},
		project() {
			return {
				title: this.content.project?.title,
				slug: this.content.project?.slug
			}
		}
	}
}
</script>

<style lang="scss">
.title-line {
	display: flex;
	justify-content: space-between;

	&__container {
		position: relative;
		display: flex;
		flex-wrap: wrap;

		&--left {
			gap: 5px;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		flex-basis: 100%;

		&--title {
			gap: 10px;
		}

		&--sub {
			gap: 20px;
		}

		&--price {
			justify-content: flex-end;

			&:hover + .tooltip {
				opacity: 1;
				visibility: visible;
				transition: all 0.2s ease-in;
			}
		}

		&--square {
			justify-content: flex-end;
		}
	}

	&__title {
		margin: 0;
		font-size: 28px;
		font-weight: 700;
		line-height: 120%;

		&--skeleton {
			width: 350px;
			height: 34px;

			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}

	&__tags {
		&--skeleton {
			width: 135px;
			height: 34px;
		}
	}

	.title-tags {
		display: flex;
		gap: 10px;

		li {
			padding: 5px 10px;
			border-radius: 4px;
			color: white;
			font-size: 12px;
			background-color: #c99d56;
			border: 1px solid #c99d56;
		}
	}

	&__build-status {
		display: block;
		padding: 5px 10px;
		color: black;
		font-size: 12px;
		border-radius: 4px;
		border: 1px solid #c99d56;

		&--skeleton {
			width: 90px;
			height: 34px;
		}
	}

	&__build-date {
		margin: 0;
		color: #808080;
		text-align: right;
		font-size: 12px;

		span {
			color: black;
		}

		&--skeleton {
			width: 135px;
			height: 34px;
		}
	}

	&__price {
		font-size: 28px;
		font-weight: 700;
		line-height: 120%;

		&--skeleton {
			width: 100px;
			height: 34px;
			margin-bottom: 5px;
		}
	}

	&__square {
		font-size: 18px;
		font-weight: 700;
		color: #c99d56;

		&--skeleton {
			width: 50px;
			height: 28px;
		}
	}

	&__project {
		font-size: 12px;
		font-weight: 700;
		color: #808080;

		a {
			color: #c99d56;

			&:hover {
				color: #b98024;
			}
		}

		&--skeleton {
			width: 145px;
			height: 17px;
		}
	}

	&__id {
		font-size: 12px;
		color: #808080;

		&--skeleton {
			width: 25px;
			height: 17px;
		}
	}

	&__time {
		font-size: 12px;
		color: #808080;

		&--skeleton {
			width: 95px;
			height: 17px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;

		&__content {
			&--title {
				flex-wrap: wrap;
			}

			&--sub {
				flex-wrap: wrap;
				gap: 10px;
			}
		}

		&__title {
			flex-basis: 100%;
			order: 2;
		}

		&__tags {
			order: 1;
		}

		&__build-status {
			order: 3;
		}

		&__build-date {
			order: 4;
		}

		&__time {
			flex-basis: 100%;
		}

		// right container

		&__container {
			&--right {
				margin-top: 10px;
				gap: 20px;
				flex-wrap: nowrap;
			}
		}

		&__square {
			display: flex;
			gap: 5px;
		}
	}
}
</style>
