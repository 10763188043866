<template>
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M23.3327 12C23.3327 17.891 18.5571 22.6667 12.666 22.6667C6.77498 22.6667 1.99935 17.891 1.99935 12C1.99935 6.10896 6.77498 1.33333 12.666 1.33333C18.5571 1.33333 23.3327 6.10896 23.3327 12ZM24.666 12C24.666 18.6274 19.2934 24 12.666 24C6.0386 24 0.666016 18.6274 0.666016 12C0.666016 5.37258 6.0386 0 12.666 0C19.2934 0 24.666 5.37258 24.666 12ZM11.4423 14.3853V14.8741H12.6923V14.5639C12.6923 14.1754 12.733 13.8434 12.8145 13.5677C12.8959 13.2857 13.0463 13.0132 13.2656 12.75C13.4849 12.4806 13.8013 12.1704 14.2149 11.8195C14.6848 11.4373 15.092 11.0771 15.4367 10.7387C15.7813 10.3941 16.0476 10.0182 16.2355 9.6109C16.4235 9.19737 16.5175 8.69925 16.5175 8.11654C16.5175 7.07018 16.1854 6.24937 15.5212 5.65414C14.8571 5.05263 13.9204 4.75188 12.7111 4.75188C11.9717 4.75188 11.3013 4.83647 10.6998 5.00564C10.0983 5.17481 9.54067 5.39411 9.02688 5.66354L9.57199 6.92293C10.0169 6.70364 10.4805 6.51567 10.963 6.35902C11.4454 6.20238 11.9968 6.12406 12.6171 6.12406C13.3815 6.12406 13.9736 6.2995 14.3934 6.65038C14.8132 6.99499 15.0231 7.49311 15.0231 8.14474C15.0231 8.55201 14.9605 8.89975 14.8352 9.18797C14.7098 9.47619 14.5031 9.76441 14.2149 10.0526C13.9329 10.3346 13.5476 10.6792 13.0588 11.0865C12.639 11.4373 12.3101 11.7694 12.072 12.0827C11.8402 12.396 11.6773 12.7343 11.5833 13.0977C11.4893 13.4549 11.4423 13.8841 11.4423 14.3853ZM11.3389 16.782C11.1321 16.9637 11.0288 17.2738 11.0288 17.7124C11.0288 18.1447 11.1321 18.4612 11.3389 18.6617C11.5457 18.8559 11.812 18.953 12.1378 18.953C12.4448 18.953 12.7048 18.8559 12.9179 18.6617C13.1372 18.4612 13.2468 18.1447 13.2468 17.7124C13.2468 17.2738 13.1372 16.9637 12.9179 16.782C12.7048 16.594 12.4448 16.5 12.1378 16.5C11.812 16.5 11.5457 16.594 11.3389 16.782Z"
			fill="#C99D56"
		/>
	</svg>
</template>
