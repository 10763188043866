<template>
	<div class="contact-card">
		<span class="contact-card__photo">
			<or-image
				v-if="content.image"
				:src="content.image[0]"
				width="60"
				height="60"
			/>
		</span>
		<div class="contact-card__info">
			<span class="contact-card__name">{{ content.title }}</span>
			<span v-if="content.position" class="contact-card__profession">
				{{ content.position }}
			</span>
			<span class="contact-card__languages">
				<LocationSvg />
				{{ content.langs?.map((itm) => $t(`language.${itm}`)).join(', ') }}
			</span>
		</div>
		<ul class="contact-card__contacts">
			<li v-for="item in contacts" :key="item.title">
				<or-button
					class="contact-card__contact"
					:kind="item.btnClass"
					@click="item.action"
				>
					<component :is="item.icon" />
				</or-button>
			</li>
		</ul>
	</div>
</template>

<script>
import LocationSvg from '@/components/svg/Location.vue'
import PhoneSvg from '@/components/svg/Solo/Phone.vue'
import WhatsAppSvg from '@/components/svg/Solo/WhatsApp.vue'
import InfoSvg from '@/components/svg/Solo/Info.vue'

export default {
	components: { LocationSvg, PhoneSvg, WhatsAppSvg, InfoSvg },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		contacts() {
			return [
				{
					title: 'Phone',
					icon: 'PhoneSvg',
					btnClass: 'main',
					action: () => window.open('tel:+' + this.content.phone, '_system')
				},
				{
					title: 'WhatsApp',
					icon: 'WhatsAppSvg',
					btnClass: 'whatsApp-btn',
					action: () => window.open(this.content.link, '_blank')
				},
				{
					title: 'Info',
					icon: 'InfoSvg',
					btnClass: 'solid-border',
					action: () =>
						this.$store.commit('showModal', {
							name: 'CreateRequestBuy'
						})
				}
			]
		}
	}
}
</script>

<style lang="scss">
.contact-card {
	padding: 20px 30px;
	background-color: rgba(0, 0, 0, 0.02);

	&__photo {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		overflow: hidden;
		float: left;
	}

	&__info {
		margin-left: 80px;
	}

	&__name {
		display: block;

		font-size: 18px;
		font-weight: 700;
	}

	&__profession {
		display: block;
		font-size: 14px;
		line-height: 18px;
		color: #c99d56;
	}

	&__languages {
		display: block;
		margin-top: 5px;
		font-size: 14px;
		color: #b98024;
		line-height: 18px;

		svg {
			width: 18px;
			height: 18px;
			margin-right: 5px;
		}
	}

	&__contacts {
		display: flex;
		flex-basis: 100%;
		gap: 10px;
		margin-top: 18px;

		li {
			width: 100%;
		}
	}

	&__contact {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 48px !important;
		padding: 12px 20px;
		vertical-align: middle;

		&.whatsApp-btn {
			background-color: #2cb23f;

			&:hover {
				background-color: #269936;
			}
		}

		&.solid-border {
			&:hover {
				svg path {
					fill: white;
				}
			}
		}
	}
}
</style>
